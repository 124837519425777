<template>
  <CustomDrawer
    style="background-color: #1e2025; position: absolute; top: 0"
    v-model="state.value"
    :offsetY="offsetY"
    menu-background="#1E2126"
    sub-menu-background="#1E2126"
    :is-loading="isLoading"
  >
    <template #menu-content>
      <ActivityPanelLocker />
      <div class="px-3">
        <v-layout flex align-start>
          <RtbControlButton @click="close" variant="grey" sm class="mt-2 px-4">
            <v-icon>close</v-icon>
          </RtbControlButton>

          <div class="ml-2">
            <h2 class="white--text">Quick Add New Activity</h2>

            <p class="grey--text text-lighten-1">
              Activity will be after ‘{{ getCurrentMission.name || "" }}’
            </p>
          </div>
        </v-layout>

        <RtbControlButton
          xs
          class="px-4"
          variant="grey"
          v-if="!!state.value && state.value !== true"
          @click="state.value = true"
        >
          <v-icon> keyboard_arrow_left </v-icon>
        </RtbControlButton>
      </div>

      <AddQuestions
        v-if="state.value === true"
        @showSubMenu="showPanel"
        @loading="setLoading"
      />

      <AddPoll v-if="state.value === pannelNames.Poll" @loading="setLoading" />

      <AddCategories
        v-if="state.value === pannelNames.Categories"
        @loading="setLoading"
      />

      <AddMultipleChoice
        v-if="state.value === pannelNames.MultipleChoice"
        @loading="setLoading"
      />
    </template>
  </CustomDrawer>
</template>
<script>
import { mapGetters } from "vuex"

import state, { close } from "./index"

import CustomDrawer from "@/components/CustomDrawer.vue"
import { RtbControlButton } from "@/components/ui"

import AddQuestions from "@/components/ActivityMenuPanels/AddQuestions.vue"
import AddPoll from "@/components/ActivityMenuPanels/AddPoll.vue"
import AddMultipleChoice from "@/components/ActivityMenuPanels/AddMultipleChoice.vue"
import AddCategories from "@/components/ActivityMenuPanels/AddCategories.vue"
import ActivityPanelLocker from "@/components/ActivityMenuPanels/ActivityPanelLocker.vue"
import { PannelNames } from "@/components/ActivityMenuPanels/ActivityMenuPanels.consts"

export default {
  name: "QuickDrawer",
  components: {
    ActivityPanelLocker,
    AddQuestions,
    RtbControlButton,
    AddPoll,
    AddMultipleChoice,
    AddCategories,
    CustomDrawer
  },
  data() {
    return {
      isLoading: false,
      state
    }
  },

  computed: {
    ...mapGetters(["gameStatus", "getCurrentMission"]),
    ...mapGetters({
      isHost: "auth/isHost"
    }),
    offsetY() {
      if (this.gameStatus.currentMode === "meeting") {
        return 0
      }

      return 40
    },
    pannelNames() {
      return PannelNames
    }
  },
  methods: {
    close,
    showPanel(panel) {
      this.state.value = panel
    },
    setLoading(val) {
      this.isLoading = val
    }
  }
}
</script>
