var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CustomDrawer", {
    staticStyle: {
      "background-color": "#1e2025",
      position: "absolute",
      top: "0",
    },
    attrs: {
      offsetY: _vm.offsetY,
      "menu-background": "#1E2126",
      "sub-menu-background": "#1E2126",
      "is-loading": _vm.isLoading,
    },
    scopedSlots: _vm._u([
      {
        key: "menu-content",
        fn: function () {
          return [
            _c("ActivityPanelLocker"),
            _c(
              "div",
              { staticClass: "px-3" },
              [
                _c(
                  "v-layout",
                  { attrs: { flex: "", "align-start": "" } },
                  [
                    _c(
                      "RtbControlButton",
                      {
                        staticClass: "mt-2 px-4",
                        attrs: { variant: "grey", sm: "" },
                        on: { click: _vm.close },
                      },
                      [_c("v-icon", [_vm._v("close")])],
                      1
                    ),
                    _c("div", { staticClass: "ml-2" }, [
                      _c("h2", { staticClass: "white--text" }, [
                        _vm._v("Quick Add New Activity"),
                      ]),
                      _c("p", { staticClass: "grey--text text-lighten-1" }, [
                        _vm._v(
                          " Activity will be after ‘" +
                            _vm._s(_vm.getCurrentMission.name || "") +
                            "’ "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                !!_vm.state.value && _vm.state.value !== true
                  ? _c(
                      "RtbControlButton",
                      {
                        staticClass: "px-4",
                        attrs: { xs: "", variant: "grey" },
                        on: {
                          click: function ($event) {
                            _vm.state.value = true
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v(" keyboard_arrow_left ")])],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.state.value === true
              ? _c("AddQuestions", {
                  on: { showSubMenu: _vm.showPanel, loading: _vm.setLoading },
                })
              : _vm._e(),
            _vm.state.value === _vm.pannelNames.Poll
              ? _c("AddPoll", { on: { loading: _vm.setLoading } })
              : _vm._e(),
            _vm.state.value === _vm.pannelNames.Categories
              ? _c("AddCategories", { on: { loading: _vm.setLoading } })
              : _vm._e(),
            _vm.state.value === _vm.pannelNames.MultipleChoice
              ? _c("AddMultipleChoice", { on: { loading: _vm.setLoading } })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.state.value,
      callback: function ($$v) {
        _vm.$set(_vm.state, "value", $$v)
      },
      expression: "state.value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }